<template>
  <main :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}">
    <div class="container">
      <section class="service-list">
        <h2 class="service-list__title" v-text="'ポイント交換先一覧'"/>

        <div class="service-list__content">
          <router-link v-for="service in exchangePointServices" :key="service.path" :to="service.path" class="item">
            <h4>{{service.name}}</h4>

            <figure>
              <img :src="service.thumbnail" alt="">
            </figure>
            <p class="sub-title" v-html="service.content"></p>
            <div class="item__point">
              <div class="item__point__detail">
                <p>最低交換ポイント</p>
                <span class="detail-point">{{ service.min_point | numberWithCommas(',') }}pt</span>
              </div>
              <div class="item__point__date">
                <p>交換日数</p>
                <span class="detail-text">{{ service.exchange_date }}</span>
              </div>
              <p v-if="service.note" class="note" v-html="service.note"></p>
            </div>
          </router-link>
        </div>
      </section>
      <div class="d-flex justify-content-center">
        <div class="note-service d-inline-block">
          <h3>▼Amazonギフトカード</h3>
          <p class="text-indent">※本プロモーション・キャンペーンは株式会社ディー・エム広告社による提供です。<br>
            本キャンペーンについてのお問い合わせはAmazonではお受けしておりません。<br>
            株式会社ディー・エム広告社【https://chibijob.com/contact】までお願いいたします。</p>
          <p class="text-indent">※Amazon、Amazon.co.jp およびそのロゴはAmazon.com, Inc. またはその関連会社の商標です。</p>
          <br>
          <h3>▼nanacoポイント</h3>
          <p class="text-indent">
            ※「nanaco」は株式会社セブン・カードサービスの登録商標です。
          </p>
          <br>
          <h3>▼LINEポイント</h3>
          <p class="text-indent">※LINEポイントはLINE株式会社が提供するサービスです。</p>
          <p class="text-indent">※LINE株式会社は、本プロモーション・キャンペーンのスポンサーではありません。</p>
          <p class="text-indent">※LINEおよびLINEロゴは、LINE株式会社の登録商標です。</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import store from '@/store'
import { EXCHANGE_POINT_QA } from '@/enum/question-answer'
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'IndexExchangePoint',

  mixins: [Common],

  data () {
    return {
      EXCHANGE_POINT_QA
    }
  },

  beforeRouteEnter (to, from, next) {
    store.dispatch('pointExchange/getPointExchangeList').then(_ => next())
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  computed: {
    ...mapState('auth', ['profile', 'token']),
    ...mapState('pointExchange', ['services']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    },
    exchangePointServices () {
      const hiddenFlag = process.env.VUE_APP_EXCHANGE_POINT_HIDDEN_FLAG || ''
      const hiddenServices = hiddenFlag.split(',')

      const exchangeServices = this.services
        .filter(service => !hiddenServices.includes(service.path))
        .map((service) => ({
          ...service,
          name: service.title,
          path: `/exchange-service/${service.path}`
        }))
      const activeServices = exchangeServices.filter((s) => s.is_show)
      return activeServices
    }
  }
}
</script>

<style lang="scss" scoped>

main {
  padding: 85px 0;

  @media #{$info-phone} {
    padding: 85px 0 40px;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 120px 0 40px;
    }
  }
  .list-QA {
    max-width: 840px;
    width: 100%;
    margin: 0 auto;
  }

  .service-list {
    margin-bottom: 60px;
    @media #{$info-phone} {
      margin-bottom: 20px;
    }
    &.padding-webview {
      padding: 45px 0 0;
    }

    h2 {
      font-family: $font-family-title;
      color: $default-green;
      text-align: center;
      margin-bottom: 40px;
      @include font-size(36px);

      @media #{$info-phone} {
        font-size: 32px;
      }
    }

    &__content, &__content::v-deep {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 -12px;

      .item {
        width: calc(100% / 3 - 24px);
        padding: 44px 30px 50px;
        border: 4px solid #E4D9AB;
        text-align: center;
        background-color: #FAFAFA;
        box-shadow: 0 6px 6px rgba(#000000, 0.08);
        border-radius: 4px;
        color: #282828;
        margin: 0 12px 24px;

        @media #{$info-tablet-horizontal} {
          padding: 30px;
        }

        @media #{$info-tablet-vertical} {
          padding: 30px 10px;
        }

        @media #{$info-phone} {
          width: 100%;
          margin-bottom: 25px;
          padding: 25px 10px;
        }

        h4 {
          @include  font-size(24px);
          margin-bottom: 20px;
          font-weight: normal;

          @media #{$info-tablet-horizontal} {
            @include font-size(18px)
          }

          @media #{$info-phone} {
            font-size: 24px;
            margin-bottom: 10px;
          }

        }

        figure {
          width: 200px;
          height: 200px;
          margin: 0 auto 34px;

          @media #{$info-phone} {
            width: 100px;
            height: 100px;
            margin: 0 auto 20px;
          }
        }

        .sub-title {
          @include font-size(14px);
          text-align: left;
          margin: 0 0 10px 0;
          &.text-indent {
            margin-left: 1rem;
          }
          b {
            @include font-size(18px);
          }
        }

        &__point {
          position: relative;
          &__detail, &__date {
            @include flex();
            align-items: center;
            justify-content: space-between;

            span.detail-point {
              font-family: $font-label;
              @include font-size(30px);
              color: #C66C44;
              font-style: italic;

              @media #{$info-tablet-horizontal} {
                @include font-size(26px)
              }

              @media #{$info-tablet-vertical} {
                @include font-size(20px)
              }

              @media #{$info-phone} {
                font-size: 30px;
              }
            }

            p {
              @include font-size(18px);
              background-color: #FAFAFA;
              position: relative;
              z-index: 1;

              @media #{$info-tablet-horizontal} {
                @include font-size(16px)
              }

              @media #{$info-tablet-vertical} {
                @include font-size(14px)
              }

              @media #{$info-phone} {
                font-size: 18px;
              }
            }

            span {
              position: relative;
              &::after {
                content: '';
                position: absolute;
                left: -50px;
                width: 87px;
                border: 1px dashed #a2a2a2;
                top: 50%;
                transform: translate(-50%, -50%);

                @media #{$info-phone} {
                  left: -30vw;
                  width: 55vw;
                }
                // fix layout border dashed with screen < 450px
                @media screen and (max-width: 450px) {
                  left: -25vw;
                  width: 46vw;
                }
              }
            }

          }

          &__date {
            overflow: hidden;

            span.detail-text {
              font-family: $font-family-title-B;
              @include font-size(18px);
              color: #73483E;

              @media #{$info-tablet-vertical} {
                @include font-size(16px)
              }

              @media #{$info-phone} {
                font-size: 18px;
              }
            }
          }

        }
      }

    }
  }

  .note {
    margin-top: 40px;
    margin-bottom: -20px;
    font-size: 11px;
    text-align: left;
  }
}
</style>
