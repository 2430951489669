export const ADVERTISING_QA = [
  {
    id: 1,
    question: 'ADVERTISINGポイント明細の判定中に反映されない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 2,
    question: 'Point Site からのメールが届かない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 3,
    question: 'ログインできない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 4,
    question: '電話番号認証ができない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 5,
    question: '電話でお問い合わせをしたい',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 6,
    question: '秘密の質問のこたえを忘れた',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  }
]

export const RECEIPT_QA = [
  {
    id: 1,
    question: 'RECEIPTポイント明細の判定中に反映されない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 2,
    question: 'Point Site からのメールが届かない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 3,
    question: 'ログインできない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 4,
    question: '電話番号認証ができない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 5,
    question: '電話でお問い合わせをしたい',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 6,
    question: '秘密の質問のこたえを忘れた',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  }
]

export const SURVEY_QA = [
  {
    id: 1,
    question: 'SURVEYポイント明細の判定中に反映されない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 2,
    question: 'Point Site からのメールが届かない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 3,
    question: 'ログインできない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 4,
    question: '電話番号認証ができない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 5,
    question: '電話でお問い合わせをしたい',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 6,
    question: '秘密の質問のこたえを忘れた',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  }
]

export const EXCHANGE_POINT_QA = [
  {
    id: 1,
    question: 'ポイント明細の判定中に反映されない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 2,
    question: 'Point Site からのメールが届かない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 3,
    question: 'ログインできない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 4,
    question: '電話番号認証ができない',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 5,
    question: '電話でお問い合わせをしたい',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  },
  {
    id: 6,
    question: '秘密の質問のこたえを忘れた',
    answer: 'POINT GETボタン上部の「予定反映」に記載のある時期を目安に[判定中]へ反映されます。\n' +
      '「予定反映」に記載のある時期を過ぎても[判定中]へ反映されない場合はお問い合わせフォームよりお問い合わせいただきますようお願いいたします。\n' +
      '\n' +
      'なお、調査につきましては、獲得条件を満たしていただいた状態でのみ承っております。\n' +
      '獲得条件をご確認いただき、条件を満たしていただいた後にお問い合わせください。\n' +
      '\n' +
      '※予定明細　なし　と記載のある広告は[判定中]への反映は行われません。\n' +
      '獲得時期を過ぎてもポイントが反映されない場合はお問い合わせフォームよりお問い合わせをお願いいたいます。\n' +
      '※ご利用いただいた広告により調査を承ることができない広告がございます。予めご了承いただきますようお願いいたします。'
  }
]
